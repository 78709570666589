import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-grid-system';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames/bind';
import styles from './GrayBox.module.scss';

const cx = classNames.bind(styles);

library.add(faCircle);

const GrayBox = ({ messages }) => (
  <div className={cx('wrapper')}>
    <Container>
      <Row>
        <Col xs={12} lg={2} offset={{ lg: 1 }}>
          <div className={cx('title')}><strong>확인</strong>해주세요!</div>
        </Col>
        <Col xs={12} lg={8}>
          <ul className={cx('list')}>
            {messages.map((message, i) => {
              return (
                <li key={i} className={cx('item')}><FontAwesomeIcon icon="circle" className={cx('dot')} />{message}</li>
              );
            })}
          </ul>
        </Col>
      </Row>
    </Container>
  </div>
);

GrayBox.propTypes = {
  messages: PropTypes.array.isRequired,
};

export default GrayBox;