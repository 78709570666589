import React from 'react';

import Banner from 'components/Contact/Order/Banner';
import Form from 'components/Contact/Order/Form';
import GrayBox from 'components/misc/GrayBox';

const OrderPage = () => (
  <div>
    <Banner />
    <Form />
    <GrayBox messages={[
      '단체 및 기업구매 상담시간 : 월~금 09:00~17:00 (토/일요일, 공휴일 휴무)',
      '단체 및 기업의 구매 신청 또는 구매상담은 홈페이지 및 카카오톡(아이디 artisee00)을 통해 접수됩니다.',
      '답변은 신청 시 기재한 메일 또는 유선으로 운영시간 내에 안내됩니다.',
      '기재 오류 및 계정문제가 발생할 경우 답변이 불가할 수 있으므로 메일주소 및 전화번호를 꼭 확인해주시기 바랍니다.',
    ]} />
  </div>
);

export default OrderPage;