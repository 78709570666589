import React from 'react';
import { ScreenClassRender } from 'react-grid-system';
import classNames from 'classnames/bind';

import styles from './Banner.module.scss';

const cx = classNames.bind(styles);

const Banner = () => (
  <ScreenClassRender render={(screenClass) => {
    const isMobile = ['xs'].includes(screenClass);
    const isTablet = ['sm', 'md'].includes(screenClass);
    const noBr = ['xs'].includes(screenClass);

    let height = 800;
    height = isTablet ? 640 : height;
    height = isMobile ? 480 : height;

    return (
      <div className={cx(['jumbotron', { mobile: isMobile }])} style={{ height }}>
        <div className={cx(['title', { noBr: !noBr }])}>아티제가 준비한 정성 가득한<br />선물의 구매 상담을 도와드립니다.</div>
        <div className={cx('subtitle', { noBr })}>아티제에서 판매하는 기프트카드 및 제품들을<br/>귀사의 <span className={cx('bold')}>의미있는 선물</span>로 준비하세요.</div>
      </div>
    );
  }} />
);

export default Banner;